<template>
  <div class="container-component" :class="isPage ? '' : 'm-0 p-0'">
    <!-- PRODUKTLISTE start -->
    <div class="row mb-4" v-if="showProductlist">
      <div class="col">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h5 class="mb-0 font-size-18">Published Products</h5>
        </div>
      </div>
    </div>

    <div class="row" v-if="showProductlist">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="mb-5">
                  <h6 class="card-title">
                    Product List
                    <span
                      class="text-muted fw-normal ms-2"
                      v-if="products?.length > 0"
                      >({{ products?.length }})</span
                    >
                  </h6>
                </div>

                <div
                  class="col-12 d-flex mb-3"
                  v-if="
                    user?.role?.id != '188AEA750CF' &&
                    user?.role?.id != '188AEA80E7A'
                  "
                >
                  <button
                    class="btn btn-sm btn-danger-light ml-auto mr-2"
                    :disabled="selectedObjects.length === 0"
                    @click="oeffneLoeschenModal"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash" />
                    <span class="ml-2">Delete selected products</span>
                  </button>
                </div>

                <div class="col-12">
                  <div class="table-responsive">
                    <div class="table align-middle">
                      <b-table
                        class="productsTable text-center"
                        ref="selectableTable"
                        :items="products"
                        :fields="fields"
                        responsive="sm"
                        selectable
                        select-mode="range"
                        tbody-tr-class="item"
                        @row-dblclicked="oeffneProduct"
                      >
                        <template v-slot:head()="header">
                          <div
                            v-tooltip.hover
                            :title="header.field.name"
                            v-if="header.field.icon && !header.field.iconSmall"
                            class="d-flex pos-r justify-content-center align-items-center"
                          >
                            <font-awesome-icon
                              class="fs-16"
                              :icon="header.field.icon"
                            />
                          </div>

                          <div
                            v-tooltip.hover
                            :title="header.field.name"
                            v-if="header.field.icon && header.field.iconSmall"
                            class="d-flex pos-r justify-content-center align-items-center"
                          >
                            <div class="pos-r">
                              <font-awesome-icon
                                class="fs-10 pos-a left-8-negative bottom-0"
                                :icon="header.field.iconSmall"
                              />
                              <font-awesome-icon
                                class="fs-16 w-18-px"
                                :icon="header.field.icon"
                              />
                            </div>
                          </div>
                        </template>

                        <template v-slot:cell()="item">
                          <span v-if="item.field.key === 'qrcode.mir'">{{
                            getMirValue(item.value)
                          }}</span>
                          <span v-else-if="item.field.key === 'qrcode.miz'"
                            >{{ item.value }}s</span
                          >
                          <span v-else-if="item.field.key === 'qrcode.mip'"
                            >{{ item.value }}%</span
                          >
                          <span v-else-if="item.field.key === 'qrcode.wfp'"
                            >{{ item.value }}ml</span
                          >
                          <span v-else-if="item.field.key === 'qrcode.wbp'"
                            >{{ item.value }}ml</span
                          >
                          <span v-else-if="item.field.key === 'qrcode.zdp'"
                            >{{ item.value }}s</span
                          >
                          <span v-else> {{ item.value }}</span>
                        </template>

                        <template v-slot:head(selected)="header">
                          <b-form-checkbox
                            ref="headerCheckbox"
                            v-model="header.selected"
                            @input="selectAllRows(header)"
                          ></b-form-checkbox>
                        </template>

                        <template v-slot:cell(selected)="row">
                          <b-form-checkbox
                            v-model="row.item.selected"
                            @input="onRowSelected(row)"
                          ></b-form-checkbox>
                        </template>

                        <template slot="cell(status)" slot-scope="{ value }">
                          <span
                            v-if="value?.name != 'Active'"
                            class="text-danger-light"
                            >{{ value.name }}</span
                          >
                          <span v-else class="text-success">{{
                            value.name
                          }}</span>
                        </template>

                        <template v-slot:cell(edit)="row">
                          <div class="clickable" @click="editProduct(row.item)">
                            <font-awesome-icon icon="fa-solid fa-pencil" />
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PRODUKTLISTE end -->

    <!-- PRODUKT ALLGEMEINE INFORMATIONEN start -->
    <div class="row mb-4" v-if="showNewProductGeneralInfo">
      <div class="col">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h5 class="mb-0 font-size-18">New product</h5>
        </div>
      </div>
    </div>

    <div class="row" v-if="showNewProductGeneralInfo">
      <div class="col-12">
        <div class="card">
          <div class="card-body newProductCard pos-r">
            <div class="row">
              <div class="col-12">
                <div class="mb-5">
                  <h6 class="card-title">Product General Information</h6>
                </div>

                <div class="row">
                  <div class="col-3">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Name"
                        v-model="newProduct.name"
                      />
                      <label>Product name</label>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="form-group">
                      <v-select
                        v-model="newProduct.category"
                        :options="filteredSuggestions"
                        :multiple="true"
                        label="name"
                        @search="filterSuggestions"
                        placeholder="Tags"
                        :searchable="false"
                      ></v-select>
                      <label>Tags</label>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="form-group">
                      <input type="hidden" v-model="newProduct.weight" />
                      <label class for="slider">Weight [g]</label>
                      <div
                        class="mt-2 d-flex align-items-center vue-slider-input"
                      >
                        <vue-slider
                          id="slider"
                          class="col-7 mr-3 slider-component"
                          v-model="newProduct.weight"
                          :min="0"
                          :max="1000"
                        ></vue-slider>
                        <input
                          class="col-3 p-0 slider-value text-dark-blue"
                          type="number"
                          v-model.number="newProduct.weight"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-3">
                    <div class="form-group">
                      <input type="hidden" v-model="newProduct.temperature" />
                      <label class for="slider">Target temperature [°C]</label>
                      <div
                        class="mt-2 d-flex align-items-center vue-slider-input"
                      >
                        <vue-slider
                          id="slider"
                          class="col-7 mr-3 slider-component"
                          v-model="newProduct.temperature"
                          :min="0"
                          :max="350"
                        ></vue-slider>
                        <input
                          class="col-3 p-0 slider-value text-dark-blue"
                          type="number"
                          v-model.number="newProduct.temperature"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <textarea
                        class="form-control h-115-px"
                        type="text"
                        placeholder="Description"
                        v-model="newProduct.description"
                      />
                      <label>Description</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row d-flex mt-auto pos-a bottom-15 w-100-p pr-5">
              <div class="col-2">
                <div
                  class="btn btn-sm btn-dark-blue"
                  @click="backToProductlist"
                >
                  <font-awesome-icon icon="fa-solid fa-chevron-left" />
                  <span class="ml-2">Back</span>
                </div>
              </div>
              <div class="col-2 ml-auto d-flex">
                <div
                  class="btn btn-sm btn-dark-blue ml-auto"
                  @click="showProductInfo"
                >
                  <span class="mr-2">Next</span>
                  <font-awesome-icon icon="fa-solid fa-chevron-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PRODUKT ALLGEMEINE INFORMATIONEN end -->

    <!-- PRODUKTINFORMATIONEN start -->
    <div class="row mb-4" v-if="showNewProductInfo">
      <div class="col">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h5 class="mb-0 font-size-18">New product</h5>
        </div>
      </div>
    </div>
    <div class="row" v-if="showNewProductInfo">
      <div class="col-12">
        <div class="card">
          <div class="card-body newProductCard pos-r">
            <div class="row">
              <div class="col-12">
                <div class="mb-5">
                  <h6 class="card-title">Product General Information</h6>
                </div>

                <div class="row mb-2">
                  <div class="col-4">
                    <div class="form-group">
                      <label>Mixing direction:</label>
                      <v-select
                        v-model="newProduct.qrcode.mir"
                        :options="mirOptions"
                        label="name"
                        :reduce="(option) => option.value"
                        placeholder="Select mixing direction"
                        :searchable="false"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <input type="hidden" v-model="newProduct.qrcode.miz" />
                      <label class for="slider"
                        >Mixing time per direction [s]</label
                      >
                      <div
                        class="mt-2 d-flex align-items-center vue-slider-input"
                      >
                        <vue-slider
                          id="slider"
                          class="col-7 mr-3 slider-component px-3"
                          v-model="newProduct.qrcode.miz"
                          :min="0"
                          :max="10"
                        ></vue-slider>
                        <input
                          class="col-3 p-0 slider-value text-dark-blue"
                          type="number"
                          v-model.number="newProduct.qrcode.miz"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <input type="hidden" v-model="newProduct.qrcode.mip" />
                      <label class for="slider"
                        >Mixer PWM [%] (Mixer force/mixer speed)</label
                      >
                      <div
                        class="mt-2 d-flex align-items-center vue-slider-input"
                      >
                        <vue-slider
                          id="slider"
                          class="col-7 mr-3 slider-component px-3"
                          v-model="newProduct.qrcode.mip"
                          :min="0"
                          :max="100"
                        ></vue-slider>
                        <input
                          class="col-3 p-0 slider-value text-dark-blue"
                          type="number"
                          v-model.number="newProduct.qrcode.mip"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <input type="hidden" v-model="newProduct.qrcode.wfp" />
                      <label class for="slider"
                        >Total amount of water [ml]</label
                      >
                      <div
                        class="mt-2 d-flex align-items-center vue-slider-input"
                      >
                        <vue-slider
                          id="slider"
                          class="col-7 mr-3 slider-component"
                          v-model="newProduct.qrcode.wfp"
                          :min="20"
                          :max="100"
                        ></vue-slider>
                        <input
                          class="col-3 p-0 slider-value text-dark-blue"
                          type="number"
                          v-model.number="newProduct.qrcode.wfp"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <input type="hidden" v-model="newProduct.qrcode.wbp" />
                      <label class for="slider"
                        >Amount of water until the 1st pause [ml]</label
                      >
                      <div
                        class="mt-2 d-flex align-items-center vue-slider-input"
                      >
                        <vue-slider
                          id="slider"
                          class="col-7 mr-3 slider-component"
                          v-model="newProduct.qrcode.wbp"
                          :min="20"
                          :max="100"
                        ></vue-slider>
                        <input
                          class="col-3 p-0 slider-value text-dark-blue"
                          type="number"
                          v-model.number="newProduct.qrcode.wbp"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-2">
                    <div class="form-group">
                      <input type="hidden" v-model="newProduct.qrcode.zdp" />
                      <label class for="slider">Pause duration [s]</label>
                      <div
                        class="mt-2 d-flex align-items-center vue-slider-input"
                      >
                        <vue-slider
                          id="slider"
                          class="col-7 mr-3 slider-component"
                          v-model="newProduct.qrcode.zdp"
                          :min="5"
                          :max="20"
                        ></vue-slider>
                        <input
                          class="col-3 p-0 slider-value text-dark-blue"
                          type="number"
                          v-model.number="newProduct.qrcode.zdp"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-2">
                    <div class="form-group">
                      <input type="hidden" v-model="newProduct.qrcode.adp" />
                      <label class for="slider">Number of pauses</label>
                      <div
                        class="mt-2 d-flex align-items-center vue-slider-input"
                      >
                        <vue-slider
                          id="slider"
                          class="col-7 mr-3 slider-component"
                          v-model="newProduct.qrcode.adp"
                          :min="0"
                          :max="5"
                        ></vue-slider>
                        <input
                          class="col-3 p-0 slider-value text-dark-blue"
                          type="number"
                          v-model.number="newProduct.qrcode.adp"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row d-flex mt-auto pos-a bottom-15 w-100-p pr-5">
              <div class="col-2">
                <div
                  class="btn btn-sm btn-dark-blue"
                  @click="backToNewProductGeneralInfo"
                >
                  <font-awesome-icon icon="fa-solid fa-chevron-left" />
                  <span class="ml-2">Back</span>
                </div>
              </div>
              <div class="col-2 ml-auto d-flex">
                <div
                  class="btn btn-sm btn-success ml-auto"
                  @click="saveNewProduct"
                >
                  <font-awesome-icon icon="fa-solid fa-floppy-disk" />
                  <span class="ml-2">Save Product</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PRODUKTINFORMATIONEN end -->
    <message-modal
      id="loeschen-modal"
      :title="selectedIds.length > 1 ? 'Delete products' : 'Delete product'"
      :text="
        selectedIds.length > 1
          ? 'Are you sure you want to delete the selected products?'
          : 'Are you sure you want to delete the selected product?'
      "
      :okText="selectedIds.length > 1 ? 'Delete products' : 'Delete product'"
      :okColor="'btn-danger-light'"
      @ok="deleteProducts"
    />
    <message-modal
      id="message-modal"
      :title="selectedIds.length > 1 ? 'Publish products' : 'Publish product'"
      :text="
        selectedIds.length > 1
          ? 'Are you sure you want to publish the selected products?'
          : 'Are you sure you want to publish the selected product?'
      "
      :okText="selectedIds.length > 1 ? 'Publish products' : 'Publish product'"
      :okColor="'btn-dark-blue'"
      @ok="publishProducts"
    />
  </div>
</template>
					
				<script>
import Api from "@/Api";
import { uuidv4 } from "@/utils/Uuid";

import Table from "@/mixins/Table";

import MessageModal from "@/components/Modals/MessageModal";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "PublishedProducts",
  components: {
    VueSlider,
    MessageModal,
  },
  mixins: [Table],
  props: {
    isPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      suggestions: [
        { id: 1, name: "Vegetarian" },
        { id: 2, name: "Vegan" },
        { id: 3, name: "Gluten-free" },
        { id: 4, name: "Organic" },
        { id: 5, name: "Sugar-free" },
      ],
      filteredSuggestions: [
        { id: 1, name: "Vegetarian" },
        { id: 2, name: "Vegan" },
        { id: 3, name: "Gluten-free" },
        { id: 4, name: "Organic" },
        { id: 5, name: "Sugar-free" },
      ],
      selectedItems: [],
      mirOptions: [
        { value: 0, name: "No rotation" },
        { value: 1, name: "Left" },
        { value: 2, name: "Right" },
        { value: 3, name: "Left-Right" },
      ],
      showProductlist: true,
      showNewProductGeneralInfo: false,
      showNewProductInfo: false,
      products: [],
      fields: [
        // { key: "selected", label: "" },
        // { key: "name", label: "Name" },
        // { key: "category", label: "Tags" },
        // { key: "weight", label: "Weight" },
        // { key: "temperature", label: "Target Temperature" },

        { key: "selected", name: "" },
        { key: "name", name: "Name" },
        {
          key: "qrcode.mir",
          name: "Mixing direction",
          icon: "fa-solid fa-fw fa-rotate",
        },
        {
          key: "qrcode.miz",
          name: "Mixing time per direction [s]",
          icon: "fa-solid fa-fw fa-clock-rotate-left",
        },
        {
          key: "qrcode.mip",
          name: "Mixer PWM [%] (Mixer force/mixer speed)",
          icon: "fa-solid fa-fw fa-gauge-high",
        },
        {
          key: "qrcode.wfp",
          name: "Total amount of water [ml]",
          icon: "fa-solid fa-fw fa-droplet",
        },
        {
          key: "qrcode.wbp",
          name: "Amount of water until the 1st pause [ml]",
          icon: "fa-solid fa-fw fa-droplet",
          iconSmall: "fa-solid fa-fw fa-pause",
        },
        {
          key: "qrcode.zdp",
          name: "Pause duration [s]",
          icon: "fa-solid fa-fw fa-pause",
          iconSmall: "fa-solid fa-fw fa-clock",
        },
        {
          key: "qrcode.adp",
          name: "Number of pauses",
          icon: "fa-solid fa-fw fa-pause",
          iconSmall: "fa-solid fa-fw fa-hashtag",
        },
      ],
      newProduct: {
        id: null,
        //status: { id: "18895B7977D", name: "development"},
        name: null,
        description: null,
        category: null,
        weight: 0,
        temperature: 0,
        creator: null,
        price: null,
        ser: null,
        published: false,
        qrcode: {
          id: null,
          status: null,
          name: null,
          web: null,
          mir: 3,
          miz: 2,
          mip: 85,
          wfp: 55,
          wbp: 20,
          zdp: 5,
          adp: 1,
          wip: null,
          wft: null,
          cs: null,
        },
      },
      user: null,
    };
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {
    await Api.get("user/").then((response) => {
      this.user = response.data;
    });

    this.getProducts();
  },
  methods: {
    getProducts() {
      Api.get("product/", {
        params: { company: this.user?.company?.id, published: true },
      }).then((response) => {
        this.products = response?.data?.content;
      });
    },
    oeffneProduct(product) {
      this.$router.push({
        name: "product",
        params: { id: product.id },
      });
    },
    filterSuggestions(search) {
      this.filteredSuggestions = this.suggestions.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(search.toLowerCase())
      );
    },
    addNewProduct() {
      this.newProduct = {
        id: null,
        //status: { id: "18895B7977D", name: "development"},
        name: null,
        description: null,
        category: null,
        weight: 0,
        temperature: 0,
        creator: null,
        price: null,
        ser: null,
        published: false,
        qrcode: {
          id: null,
          status: null,
          name: null,
          web: null,
          mir: 3,
          miz: 2,
          mip: 85,
          wfp: 55,
          wbp: 20,
          zdp: 5,
          adp: 1,
          wip: null,
          wft: null,
          cs: null,
        },
      };

      this.showProductlist = false;
      this.showNewProductGeneralInfo = true;
      this.showNewProductInfo = false;
    },
    backToProductlist() {
      this.showProductlist = true;
      this.showNewProductGeneralInfo = false;
      this.showNewProductInfo = false;
    },
    showProductInfo() {
      this.showProductlist = false;
      this.showNewProductGeneralInfo = false;
      this.showNewProductInfo = true;
    },
    backToNewProductGeneralInfo() {
      this.showProductlist = false;
      this.showNewProductGeneralInfo = true;
      this.showNewProductInfo = false;
    },
    async saveNewProduct() {
      //this.newProduct.qrcode.company = { id: this.user?.company?.id };

      this.newProduct.category = this.newProduct.category
        ?.map((tag) => tag.name)
        ?.join(", ");

      this.newProduct.qrcode.company = this.user.company;

      await Api.post("product/", this.newProduct).then((response) => {
        console.log(response.data);
        this.$notify({
          type: "success",
          title: "Action successful",
          text: "Product successfully created.",
        });
      });
      this.getProducts();
      this.showProductlist = true;
      this.showNewProductGeneralInfo = false;
      this.showNewProductInfo = false;
    },
    publishProducts() {
      Api.put("product/", this.selectedIds).then(() => {
        this.getProducts();
      });
    },
    deleteProducts() {
      Api.delete("product/", { data: this.selectedIds }).then(() => {
        this.getProducts();
      });
    },
    oeffneLoeschenModal() {
      this.$bus.$emit("open-modal", "loeschen-modal");
    },
    oeffneMessageModal() {
      this.$bus.$emit("open-modal", "message-modal");
    },
    getMirValue(val) {
      if (val) {
        let mirName = this.mirOptions.find((opt) => opt.value === val)?.name;
        return mirName;
      }
      return "-";
    },
  },
};
</script>	  
  <style lang="scss">
</style>
					